import React, { useEffect, useState } from 'react';
import './footer.css';
import EnlacesFooter from '../EnlacesFooter/EnlacesFooter';
import { useSelector } from 'react-redux';

const Footer = () => {
  const { hideImages, enlargeText, grayscale } = useSelector((state) => state.accessibility);
  const [homes, setHomes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const url = "https://cmsportal.hidalgo.gob.mx";

  useEffect(() => {
    fetch(`${url}/homes`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setHomes(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
        setError(error.toString());
        setIsLoading(false);
      });
  }, []);

  const currentYear = new Date().getFullYear();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const baseFooter = homes.length > 0 ? homes[0].Footer : null;
  const BaseFooterEnlacesAtencion = homes.length > 0 ? homes[0].FooterEnlacesAtencionCiudadana : null;
  const BaseFooterEnlacesGobierno = homes.length > 0 ? homes[0].FooterEnlacesGobierno : null;
  const BaseFooterEnlacesSecretarias = homes.length > 0 ? homes[0].FooterEnlancesSecretarias : null;
  const BaseFooterEnlacesServicios = homes.length > 0 ? homes[0].FooterEnlacesServicios : null;
  const BaseFooterSocialMedia = homes.length > 0 ? homes[0].FooterSocialMedia : null;

  if (!baseFooter) {
    return <div>Loading</div>;
  }

  return (
    <div className={`container-footer ${hideImages ? 'image-none' : ''} ${grayscale || homes[0]?.Veda ? 'image-gray' : ''} ${enlargeText === 1 ? 'level1' : enlargeText === 2 ? 'level2' : enlargeText === 3 ? 'level3' : ''}`}>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-4 col-sm-6 col-12'>
            <img className='img-footer' src={`${url}${homes[0]?.Veda ? baseFooter.logoVeda.url : baseFooter.logo.url}`} alt='Escudo de Hidalgo' />
            <div className='seccion-contacts'>
              <div className='box-text'>
                <p>{baseFooter.Direccion1}</p>
                <p>{baseFooter.Direccion2}</p>
              </div>
              <div className='box-text'>
                <p>{baseFooter.ContactoText}</p>
                <p>{baseFooter.ContactoPhone}</p>
              </div>
              <div className='box-text'>
                <p>{baseFooter.EmergencyText}</p>
                <p className='bold'>{baseFooter.EmergencyPhone}</p>
              </div>
              <div className='box-text'>
                <p>{baseFooter.DenunciaText}</p>
                <p className='bold'>{baseFooter.DenunciaPhone}</p>
              </div>
              <div className='box-text'>
                <p className='footer-sub'><a href={baseFooter.AvisoPrivacidadUrl}>{baseFooter.AvisoPrivacidadText}</a></p>
              </div>
              <div className='box-text'>
                <p className='copyright'>{baseFooter.CopyShortText} {currentYear} {baseFooter.CopyLongText}</p>
              </div>
            </div>
          </div>
          <div className='col-sm-8 col-12'>
            <div className='row'>
              <div className='col-sm-6 col-12'>
                <EnlacesFooter data={BaseFooterEnlacesGobierno ? BaseFooterEnlacesGobierno.FooterData : []} title={BaseFooterEnlacesGobierno.Titulo} />
              </div>
              {/* <div className='col-sm-6 col-12'>
                <EnlacesFooter data={BaseFooterEnlacesAtencion ? BaseFooterEnlacesAtencion.FooterDataAtencionCiudadana : []} title={BaseFooterEnlacesAtencion.Titulo} />
              </div> */}
              <div className='col-sm-6 col-12'>
                <EnlacesFooter data={BaseFooterEnlacesSecretarias ? BaseFooterEnlacesSecretarias.FooterDataSecretarias : []} title={BaseFooterEnlacesSecretarias.Titulo} />
              </div>
            </div>
            <div className='row'>
              {/* <div className='col-sm-6 col-12'>
                <EnlacesFooter data={BaseFooterEnlacesSecretarias ? BaseFooterEnlacesSecretarias.FooterDataSecretarias : []} title={BaseFooterEnlacesSecretarias.Titulo} />
              </div> */}
              {/* <div className='col-sm-6 col-12'>
                <EnlacesFooter data={BaseFooterEnlacesServicios ? BaseFooterEnlacesServicios.FooterDataServicios : []} title={BaseFooterEnlacesServicios.Titulo} />
                <div className='social-media'>
                  {
                    BaseFooterSocialMedia.FooterDataSocialMedia.map((item, index) => (
                      <div className='media' key={`social-media-${index}`}>
                        <a href={`${item.url}`}>
                          <img src={`${url + item.logo.url}`}/>
                        </a>
                      </div>
                    ))
                  }
                </div>
              </div> */}
              <div className='col-sm-6 col-12'>
                <div className='social-media'>
                  {
                    BaseFooterSocialMedia.FooterDataSocialMedia.map((item, index) => (
                      <div className='media' key={`social-media-${index}`}>
                        <a target='_blank' href={`${item.url}`}>
                          <img src={`${url + item.logo.url}`} />
                        </a>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
