import React from 'react';

const EnlacesFooter = ({ data, title }) => {
    return (
        <div className='gob-seccion'>
            <div>
                <p className='footer-sub'>{title}</p>
                <div>
                    {
                        data.map((item, index) => (
                            <a key={index} href={item.Url} target='_blank' rel="noopener noreferrer">{item.Texto}</a>
                        ))
                    }
                </div>
            </div>

        </div>
    )
}

export default EnlacesFooter;
