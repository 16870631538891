import React from 'react';
import './convocatorias.css';
import { convocatorias } from '../../json/convocatorias';

const Convocatorias = ({ homes, url }) => {
    const isMobile = window.innerWidth <= 576;

    return (
        <div className='main-convocatorias'>
            <ul className="nav nav-tabs" id="myTabs" role="tablist">
                {
                    homes[0]?.convocatorias.convocatoriasData.map((item, index) => (
                        <li className="nav-item" role="presentation" key={index}>
                            <button
                                className={`nav-link ${index === 0 ? "active" : ""}`}
                                id={"tab" + index + "-tab"}
                                data-bs-toggle="tab"
                                data-bs-target={"#tab" + index}
                                type="button"
                                role="tab"
                                aria-controls={"tab" + index}
                                aria-selected={index === 0 ? "true" : "false"}
                            >{item.convocatoriaTitulo}</button>
                        </li>

                    ))
                }
            </ul>
            <div className="tab-content mt-2" id="myTabsContent">
                {
                    homes[0]?.convocatorias.convocatoriasData.map((item, index) => (
                        <div className={`tab-pane fade ${index === 0 ? "show active" : ""}`} id={"tab" + index} role="tabpanel" aria-labelledby={"tab" + index + "-tab"} key={index}>
                            <div className='row'>
                                <div className='col-sm-8 col-12 no-p'>
                                    <div className='tab-img-convocatoria' style={{ backgroundImage: `url("${isMobile ? url+item.convocatoriaMobileImg.url : url + item.convocatoriaImg.url}")` }}>

                                    </div>
                                </div>
                                <div className='col-md-4 col-12 convocatorias-data'>
                                    <div>
                                        <h3>{item.convocatoriaTitulo}</h3>
                                        <p className='convocatorias-description'>{item.convocatoriaDescripcion}</p>
                                        <a href={item.convocatoriaLink} target='_blank'>Ir al Portal</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Convocatorias;
