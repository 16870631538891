import React, { useEffect, useState } from 'react';
import './ultimasNoticias.css';
import Paginador from '../Paginador/Paginador';

const UltimasNoticias = () => {
  const [data, setData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    fetch('https://comunicacion-a.hidalgo.gob.mx/traer/notas', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        setData(data?.success ? data.success.notas : []);
      })
      .catch(error => console.error(error));
  }, []);

  const formatFecha = fecha => {
    const date = new Date(Number(fecha));
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('es-ES', options);
  };

  const handlePageChange = (newPageIndex) => {
    setPageIndex(newPageIndex);
  };

  const itemPorPagina = 3;
  const paginasTotales = Math.ceil(data.length / itemPorPagina);
  const paginaData = data.slice(pageIndex * itemPorPagina, (pageIndex + 1) * itemPorPagina);

  return (
    <div>
      {
        paginaData.map(nota => {
          const galleryItem = nota.content?.find(item => item.type === 'Gallery');
          // console.log("galleryitem", galleryItem);
          const imageUrl = galleryItem?.urls?.[0] || 'default-image-url';
          // console.log("imgsUrl", imageUrl);
          return (
            <div className='container' key={nota.id}>
              <div className='row row-noticias'>
                <div className='col-md-4 col-12'>
                  {
                    imageUrl ? <div className="img-nota" style={{ backgroundImage: `url(${imageUrl})` }}></div> : null
                  }

                </div>
                <div className='col-sm-8 col-12'>
                  <h4 className='sec-nota'>{nota.secretaria}</h4>
                  <p>{formatFecha(nota.date)}</p>
                  <h4 className='title-nota'>{nota.title}</h4>
                  <p>{nota.descripcion}</p>
                  <a className='enlace-nota' href={"https://comunicacion-a.hidalgo.gob.mx/detalles/" + nota.uuid} target='_blank' rel="noopener noreferrer">Leer Completo</a>
                </div>
              </div>
            </div>
          );
        })
      }
      <Paginador paginasTotales={paginasTotales} paginaActual={pageIndex} cambioPagina={handlePageChange} />
    </div>
  );
}

export default UltimasNoticias;
