export const iniciativasG = [
    {
        "imagen" : "./assets/imgs/iniciativas/rutas.png",
        "imgMobile" : "./assets/imgs/iniciativas/rutas-mobile.png",
        "titulo" : "Rutas de la Transformación",
        "url" : "http://rutasdelatransformacion.hidalgo.gob.mx",
        "descripcion" : "En estas nuevas Rutas traemos servicios para todas y todos. Conoce más."
    },
    {
        "imagen" : "./assets/imgs/iniciativas/calles.png",
        "imgMobile" : "./assets/imgs/iniciativas/calles-mobile.png",
        "titulo" : "Calles Renovadas",
        "url" : "https://s-obraspublicas.hidalgo.gob.mx",
        "descripcion" : "Conoce las vialidades que serán rehabilitadas, toma tus precaciciones."
    },
    {
        "imagen" : "./assets/imgs/iniciativas/migrantes.png",
        "imgMobile" : "./assets/imgs/iniciativas/migrantes-mobile.jpg",
        "titulo" : "Programa de Atención a Migrantes",
        "url" : "https://sebiso.hidalgo.gob.mx/Psatencionm",
        "descripcion" : "El programa de atención al migrante favorece la condición social de las y los migrantes hidalguenses a través de la ejecución de acciones."
    },
    {
        "imagen" : "./assets/imgs/iniciativas/extorcion.png",
        "imgMobile" : "./assets/imgs/iniciativas/extorcion-mobile.png",
        "titulo" : "Prevención de Extorsiones",
        "url" : "https://www.facebook.com/SeguridadPublicaHGO",
        "descripcion" : "¡ALERTA DE EXTORSIÓN! Los delincuentes llaman e intentan intimidarte con AMENAZAS ¡Denuncia!"
    }
]