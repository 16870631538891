import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import './buzon.css';

const Buzon = ({ icon, id }) => {
    const [isShow, setIsShow] = useState(false);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [text, setText] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [sendReq, setSendReq] = useState("");

    const handleClick = () => {
        setIsShow(!isShow);
        setSendReq("");
    };


    let handleSubmit = async (e) => {
        e.preventDefault();
        try {
            var data = {
                nombre: name,
                phone: phone,
                text: text,
                email: email
            };

            let res = await fetch("https://www.hidalgo.gob.mx/sendmail", {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (res.status === 200) {
                setName("");
                setEmail("");
                setPhone("");
                setText("");
                setSendReq(true);
                setMessage("Tu solicitud fue enviada correctamente");
            } else {
                setMessage("Ocurrio un error al enviar tu solicitud");
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <button data-tooltip-id={id} className='button-buzon' onClick={handleClick}>
                <img src={icon} />
            </button>
            {isShow &&
                <div id="buzon-window">
                    <div className="container-fluid buzon-cont">
                        <div className="row-buzon row">
                            <div className="col-12 col-md-6 buzon-left">
                                <div className="col-left">
                                    <FontAwesomeIcon icon={faCircleXmark} className="close-circle" onClick={handleClick} />
                                    <h4>Buzón Ciudadano</h4>
                                    <p>
                                        Queremos escucharte, conocer de primera mano las necesidades e inquietudes de la ciudadanía. Utiliza este espacio como herramienta de cercanía que nos ayuda a mantener el trabajo de gobierno al alcance de todas y todos
                                    </p>
                                    {/* <img src= "https://cdn.hidalgo.gob.mx/plantilla_secretarial/assets/ave.svg"/> */}
                                    {/* <h5>primero el pueblo</h5> */}
                                    <a href="https://gobierno.hidalgo.gob.mx/AvisoPrivacidad"><small id="emailHelp" className="form-text">Ver aviso de privacidad</small></a>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 buzon-right">
                                <div className="col-right">
                                    <form onSubmit={handleSubmit} className="form-group">
                                        <label htmlFor="buzonNombre">Nombre</label>
                                        <input
                                            type="text"
                                            value={name}
                                            placeholder="¿Cómo te llamas?"
                                            onChange={(e) => setName(e.target.value)}
                                            id="buzonNombre"
                                            className="form-control"
                                        />
                                        <label htmlFor="buzoneEmail">Email</label>
                                        <input
                                            type="text"
                                            value={email}
                                            placeholder="¿A dónde te contestamos?"
                                            onChange={(e) => setEmail(e.target.value)}
                                            id="buzonEmail"
                                            className="form-control"
                                        />
                                        <label htmlFor="buzonPhone">Teléfono</label>
                                        <input
                                            type="text"
                                            value={phone}
                                            placeholder="¿A qué número te marcamos?"
                                            onChange={(e) => setPhone(e.target.value)}
                                            id="buzonPhone"
                                            className="form-control"
                                        />
                                        <label htmlFor="buzonText">Asunto</label>
                                        <textarea
                                            id="buzonText"
                                            className="form-control"
                                            rows="5.5"
                                            value={text}
                                            onChange={(e) => setText(e.target.value)}
                                        />
                                        <button className="btn btn-gold" type="submit">Enviar</button>
                                        {!sendReq ? null : message ? <div class="alert alert-success alert-req" role="alert">{message}</div>
                                            : <div class="alert alert-danger alert-req" role="alert">{message}</div>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="overlay" onClick={handleClick} />
                </div>
            }
        </>
    );
}

export default Buzon;