export const tramites = [
    {
        "imagenUrl" : "/assets/imgs/tramitesImgs/1.png",
        "tramiteNombre" : "Licencia para conucir vehículos",
        "url" : "https://ruts.hidalgo.gob.mx/ver/10614"
    },
    {
        "imagenUrl" : "/assets/imgs/tramitesImgs/2.png",
        "tramiteNombre" : "Expedición de constancia de NO antecedentes penales",
        "url" : "https://ruts.hidalgo.gob.mx/ver/572"
    },
    {
        "imagenUrl" : "/assets/imgs/tramitesImgs/3.png",
        "tramiteNombre" : "Alta vehículo particular",
        "url" : "https://ruts.hidalgo.gob.mx/ver/1735"
    },
    {
        "imagenUrl" : "/assets/imgs/tramitesImgs/4.png",
        "tramiteNombre" : "Expedición de constancias de NO inhabilitación",
        "url" : "https://ruts.hidalgo.gob.mx/ver/447"
    },
    {
        "imagenUrl" : "/assets/imgs/tramitesImgs/5.png",
        "tramiteNombre" : "Expedición de actas de registro familiar",
        "url" : "https://ruts.hidalgo.gob.mx/ver/1323"
    }
]