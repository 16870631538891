import React from 'react';
import './iniciativasG.css';
import { iniciativasG } from '../../json/iniciativasG';

const IniciativasG = ({ homes, url }) => {
  
  const isMobile = window.innerWidth <= 576;
  
  return (
    <>
      {
        homes[0]?.iniciativasG.iniciativasData.map((item, index) => (
          <div key={index} className='col-sm-6 col-12 iniciativas-container'>
            <div className='img-iniciativas' style={{ backgroundImage: `url("${ isMobile ? url+item.iniciativasDataImgMobile.url : url+item.iniciativasDataImg.url }")` }}>
            </div>
            <div className='iniciativas-data'>
              <h3><a href={item.iniciativaDataLink} target='_blank'>{item.iniciativaDataTitulo}</a></h3>
              <p>{item.iniciativaDataDescripcion}</p>
            </div>
          </div>
        ))
      }
    </>
  )
}

export default IniciativasG