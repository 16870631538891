export const convocatorias = [
    {
        "nombre" : "Portal Tributario",
        "imagen" : "/assets/imgs/convocatorias/refrendo.png",
        "imgMobile" : "/assets/imgs/convocatorias/refrendo-mobile.png",
        "titulo" : "Portal Tributario",
        "descripcion" : "Facilita el cumplimiento de obligaciones tributarias, la presentación de declaraciones y la obtención de información relevante, contribuyendo a una gestión tributaria transparente y simpliﬁcada.",
        "url" : "https://portaltributario.hidalgo.gob.mx/"
    },
    {
        "nombre" : "Hidalgo Travel",
        "imagen" : "/assets/imgs/convocatorias/turismo.png",
        "imgMobile" : "/assets/imgs/convocatorias/turismo-mobile.png",
        "titulo" : "Hidalgo Travel",
        "descripcion" : "Descubre las maravillas de Hidalgo: paisajes cautivadores, rica historia y hospitalidad única. ¡Vive experiencias inolvidables en Hidalgo!",
        "url" : "https://hidalgo.travel/wordpress/"
    },
    {
        "nombre" : "Trámites",
        "imagen" : "/assets/imgs/convocatorias/ruts.png",
        "imgMobile" : "/assets/imgs/convocatorias/ruts-mobile.png",
        "titulo" : "Ruts",
        "descripcion" : "En este portal puedes consultar y realizar trámites de manera rápida, sin la necesidad de hacer filas; desde tu computadora, móvil o tableta.",
        "url" : "https://ruts.hidalgo.gob.mx/"
    },
    {
        "nombre" : "Unidad de Servicios Estatales",
        "imagen" : "/assets/imgs/convocatorias/use.png",
        "imgMobile" : "/assets/imgs/convocatorias/use-mobile.png",
        "titulo" : "USE",
        "descripcion" : "En la USE acercamos trámites, servicios y programas del Gobierno del estado. ¡Acércate a tu USE!",
        "url" : "https://www.facebook.com/p/USE-Hidalgo-100081504834732"
    },
    {
        "nombre" : "Programas Bienestar",
        "imagen" : "/assets/imgs/convocatorias/programas.png",
        "imgMobile" : "/assets/imgs/convocatorias/programas-mobile.png",
        "titulo" : "Programas Sociales",
        "descripcion" : "Combatimos la pobreza y marginación con estrategias que fomentan participación ciudadana y corresponsabilidad social; conoce los programas.",
        "url" : "https://sebiso.hidalgo.gob.mx/Programas"
    }
]