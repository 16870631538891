import React, { useEffect, useState } from 'react';
import './inicio.css';
import { tramites } from '../../json/tramites';
import Convocatorias from '../Convocatorias/Convocatorias';
import IniciativasG from '../IniciativasG/IniciativasG';
import UltimasNoticias from '../UltimasNoticias/UltimasNoticias';
import { carouselhome } from '../../json/carouselInicio';
import ModalConvocatoria from '../Modal/ModalConvocatoria';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css';
import { useSelector } from 'react-redux';

const Inicio = () => {
  const { hideImages, enlargeText, grayscale } = useSelector((state) => state.accessibility);
  const isMobile = window.innerWidth <= 600;
  const [homes, setHomes] = useState([]);
  const [slidesViewTramite, setSlidesViewTramite] = useState(0);
  const [slidesViewEnlace, setSlidesViewEnlace] = useState(0);
  const [dataNoticias, setDataNoticias] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const url = "https://cmsportal.hidalgo.gob.mx";
  const maxLengthBannerTitle = 37;
  const maxLengthBannerDescription = 83;

  useEffect(() => {
    fetch('https://comunicacion-a.hidalgo.gob.mx/traer/notas', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        setDataNoticias(data?.success ? data.success.notas : []);
      })
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    const style = document.createElement('style');
    style.id = 'scrollbarStyle';

    if (homes[0]?.Veda || grayscale) {
      style.innerHTML = `
            ::-webkit-scrollbar-thumb {
              background: var(--grayDark);
            }
            ::-webkit-scrollbar-thumb:vertical:active {
              background: var(--grayDark);
            }
        `;
    } else {
      style.innerHTML = `
            ::-webkit-scrollbar-thumb {
              background-color: var(--pink);
            }
            ::-webkit-scrollbar-thumb:vertical:active {
              background-color: var(--pink);
            }
        `;
    }

    document.head.appendChild(style);
  }, [homes[0]?.Veda, grayscale]);

  const formatFecha = fecha => {
    const date = new Date(Number(fecha));
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('es-ES', options);
  };

  useEffect(() => {
    function handleResize() {
      const newWidth = window.innerWidth;
      if (newWidth <= 600) {
        setSlidesViewTramite(1);
        setSlidesViewEnlace(1);
      } else if (newWidth >= 601 && newWidth <= 1024) {
        setSlidesViewTramite(2);
        setSlidesViewEnlace(2);
      } else {
        setSlidesViewTramite(4);
        setSlidesViewEnlace(3);
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    fetch(`${url}/homes`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setHomes(data);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, []);

  const mostrarUltimasNoticias = homes?.length > 0 ? homes[0].UltimasNoticias?.MostrarUltimasNoticias : false;
  const mostrarModal = homes?.length > 0 ? homes[0].modalconvocatoria[0]?.activo : false;
  const mostrarIniciativas = homes?.length > 0 ? homes[0]?.IsVisibleIniciativas : false;

  useEffect(() => {
    if (mostrarModal) {
      const modalConvocatoria = homes[0]?.modalconvocatoria?.[0];

      if (modalConvocatoria) {
        const { activo, fechaInicio, fechaFin } = modalConvocatoria;

        if (fechaInicio === null || fechaFin === null) {
          if (activo) {
            setModalOpen(true);
          }
        } else {
          const inicio = new Date(fechaInicio);
          const fin = new Date(fechaFin);
          const fechaActual = new Date();

          if (fechaActual >= inicio && fechaActual <= fin) {
            setModalOpen(true);
          }
        }
      }
    }
  }, [mostrarModal, homes]);


  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const openUrl = (url) => {
    window.open(url, '_blank');
  }

  return (

    homes && (
      <div className={`inicio ${hideImages || homes[0]?.Veda ? 'image-none' : ''} ${grayscale || homes[0]?.Veda ? 'image-gray' : ''} ${enlargeText === 1 ? 'level1' : enlargeText === 2 ? 'level2' : enlargeText === 3 ? 'level3' : ''}`}>
        <div className='carousel-inicio'>
          <Swiper
            className='carousel'
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            loop={true}
            autoplay={{
              delay: 3000,
              pauseOnMouseEnter: true
            }}
            spaceBetween={50}
            slidesPerView={1}
          >
            {
              homes[0]?.banner.bannerData.map((slide, index) => (
                <SwiperSlide key={index} className='carousel-slide'>
                  <div
                    className='content-slide'
                    style={{
                      backgroundImage: `url(${hideImages || homes[0]?.Veda ? '' : isMobile ? url + slide.bannerImgMobile.url : url + slide.bannerImg.url})`
                    }}
                  >
                    {/* <div className='text-box'>
                      <div>
                        <h3>
                          {slide.bannerTitle.length > maxLengthBannerTitle
                            ? `${slide.bannerTitle.substring(0, maxLengthBannerTitle)}...`
                            : slide.bannerTitle}
                        </h3>
                        <p>
                          {slide.bannerDescription > maxLengthBannerDescription
                            ? `${slide.bannerDescription.substring(0, maxLengthBannerDescription)}...`
                            : slide.bannerDescription}
                        </p>
                      </div>
                      <div>
                        <a href={`${slide.bannerURL}`} target='_blank'>Ver Más</a>
                      </div>
                    </div> */}
                  </div>
                </SwiperSlide>
              ))
            }
          </Swiper>
        </div>

        <div className='container tramites'>
          <h1 className='title'>{homes.length > 0 ? homes[0]?.tramites.tituloPrincipal : 'Loading...'}</h1>
          <div className="search">
            <input
              type="text"
              placeholder="Buscar..."
              onKeyPress={({ key, target: { value } }) => {
                if (key === 'Enter') {
                  window.location.href = "https://ruts.hidalgo.gob.mx/?text=" + value;
                }
              }}
            />
            <svg className="icon-search" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" viewBox="-8 -3 25 23">
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
          </div>
          <div className='carousel-tramites'>
            <Swiper
              modules={[Autoplay, Pagination, Navigation]}
              loop={true}
              autoplay={{
                delay: 3000,
                pauseOnMouseEnter: true
              }}
              spaceBetween={15}
              slidesPerView={slidesViewTramite}

            >
              {
                homes[0]?.tramites.tramiteData.map((item, index) => (
                  <SwiperSlide className='slide' key={`tramite-${index}`}>
                    <div className='content-slide'>
                      <div onClick={() => openUrl(item.tramiteLink)} className='logo-tramites'>
                        <img src={`${url + item.tramiteImg?.url}`} />
                      </div>
                      <div className='box-second'>
                        <div>
                          <p onClick={() => openUrl(item.tramiteLink)} className='name'>{item.tramiteTitulo}</p>
                        </div>
                        <div className='link'>
                          <div className='tipo'>
                            <div>
                              <img src={`${url + item.tipoTramiteImg[0]?.url}`} />
                            </div>
                            <p>{item.tipoTramite}</p>
                          </div>
                          <div style={{display: 'flex', justifyContent: 'end'}}>
                            <a href={item.tramiteLink} target='_blank'>Ver más</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </div>
          <div className='all-tramites'>
            <a href={`${homes[0]?.tramites.todosLosTramites}`} target='_blank'>Ver todos los trámites</a>
          </div>
        </div>

        <div className='container enlaces-rapidos'>
          <div className='box-title'>
            <h1 className='title'>{homes[0]?.convocatorias.tituloPrincipal}</h1>
            <hr className='hr-gob' />
          </div>
          <div className='carousel-enlaces'>
            <Swiper
              modules={[Autoplay, Pagination, Navigation]}
              loop={true}
              autoplay={{
                delay: 3000,
                pauseOnMouseEnter: true
              }}
              spaceBetween={15}
              slidesPerView={slidesViewEnlace}

            >
              {
                homes[0]?.convocatorias?.convocatoriasData && homes[0]?.convocatorias?.convocatoriasData.map((item, index) => (
                  <SwiperSlide className='slide' key={`enlace-${index}`}>
                    <div className='content-slide'>
                      <div className='logo-enlace'>
                        <img src={`${url + item.convocatoriaImg?.url}`} />
                      </div>
                      <a target='_blank' className='title-enlace' href={`${item.convocatoriaLink}`}>
                        <p>
                          {item.convocatoriaTitulo}
                        </p>
                      </a>
                      <p className='description'>
                        {item.convocatoriaDescripcion}
                      </p>
                    </div>
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </div>
          {/* <div className='row'>
            <div className='col-12'>
              <Convocatorias homes={homes} url={url} />
            </div>
          </div> */}
        </div>

        <div className='container participacion'>
          <div className='box-title'>
            <h1 className='title'>{homes[0]?.participacion?.tituloPrincipal}</h1>
            <hr className='hr-gob' />
          </div>
          <div className='box-content'>
            {
              homes[0]?.participacion.participacionData.map((item, index) => (
                <div className='item-participacion' key={`item-participacion-${index}`}>
                  <a target='_blank' href={`${item.url}`}><h6>{item.titulo}</h6></a>
                  <p>{item.descripcion}</p>
                  <div className='imagen-part'>
                    <img src={`${url}${isMobile ? item.imagenMobile.url : item.imagen.url}`} />
                  </div>
                </div>
              ))
            }
          </div>
        </div>

        {
          mostrarIniciativas && (
            <div className='container iniciativas'>
              <div className='box-title'>
                <h1 className='title'>{homes[0]?.iniciativasG.iniciativasTitulo}</h1>
                <hr className='hr-gob' />
              </div>
              <div className='box-content row'>
                {
                  homes[0]?.iniciativasG?.iniciativasData.map((item, index) => (
                    <div className='item-iniciativa col-12 col-md-6' key={`iniciativa-${index}`}>
                      <a target='_blank' href={`${item.iniciativaDataLink}`}><h6>{item.iniciativaDataTitulo}</h6></a>
                      <p>{item.iniciativaDataDescripcion}</p>
                    </div>
                  ))
                }
              </div>
              {/* <div className='row'>
                <IniciativasG homes={homes} url={url} />
              </div> */}
            </div>
          )
        }
        {mostrarUltimasNoticias && !homes[0]?.Veda && (
          <div className='container noticias'>
            <div className='box-title'>
              <h1 className='title'>Últimas noticias</h1>
              <hr className='hr-gob' />
            </div>
            <div className='carousel-noticias'>
              <Swiper
                modules={[Autoplay, Pagination, Navigation]}
                pagination={{
                  clickable: true,
                }}
                loop={true}
                autoplay={{
                  delay: 3000,
                  pauseOnMouseEnter: true
                }}
                spaceBetween={15}
                slidesPerView={slidesViewTramite}

              >
                {
                  dataNoticias && dataNoticias.map((item, index) => {
                    const galleryItem = item.content?.find(item => item.type === 'Gallery');
                    const imageUrl = galleryItem?.urls?.[0] || 'default-image-url';
                    return (
                      <SwiperSlide className='slide' key={`noticia-${index}`}>
                        <div className='content-slide'>
                          <div className='imagen-noticia'>
                            <img src={`${imageUrl}`} />
                          </div>
                          <h6 className='noticia-title'>
                            {item.title}
                          </h6>
                          <p className='noticia-secretaria'>
                            {item.secretaria}
                          </p>
                          <div className='box-fecha'>
                            <div className='fecha-dot'></div>
                            <p className='noticia-fecha'>
                              {formatFecha(item.date)}
                            </p>
                          </div>
                          <p className='noticia-descripcion'>
                            {item.descripcion}
                          </p>
                          <a className='noticia-enlace' target='_blank' href={`https://comunicacion-a.hidalgo.gob.mx/detalles/${item.uuid}`}>
                            Leer más
                          </a>
                        </div>
                      </SwiperSlide>
                    );
                  })
                }
              </Swiper>
            </div>
            <div className='all-noticias'>
              <a href={`https://comunicacion-a.hidalgo.gob.mx/`} target='_blank'>Todas las noticias</a>
            </div>
            {/* <div className='row'>
              <div className='col-12'>
                <UltimasNoticias />
              </div>
            </div> */}
          </div>
        )}
        {/* <button onClick={openModal}>Abrir Modal</button> */}
        {mostrarModal && <ModalConvocatoria isOpen={isModalOpen} onClose={closeModal} data={homes[0]?.modalconvocatoria} />}
      </div>
    )
  )
}

export default Inicio