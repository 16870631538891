import { configureStore } from '@reduxjs/toolkit';
import accessibilityReducer from './slicers/accessibilitySlice';
import { loadState, saveState } from './slicers/localStorage';

const persistedState = loadState();

export const store = configureStore({
  reducer: {
    accessibility: accessibilityReducer,
  },
  preloadedState: {
    accessibility: persistedState || {
      hideImages: false,
      enlargeText: 1,
      grayscale: false,
    },
  },
});

store.subscribe(() => {
  saveState(store.getState().accessibility);
});