import React from 'react';
import './paginador.css';

const Paginador = ({ paginasTotales, paginaActual, cambioPagina }) => {
  const handlePageClick = (numeroPagina) => {
    cambioPagina(numeroPagina);
  };

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination justify-content-center">
        <li className={`page-item ${paginaActual === 0 ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageClick(Math.max(0, paginaActual - 1))} tabIndex="-1" aria-disabled={paginaActual === 0}>Anterior</button>
        </li>

        {paginaActual > 0 && (
          <li className="page-item">
            <button className="page-link" onClick={() => handlePageClick(paginaActual - 1)}>{paginaActual}</button>
          </li>
        )}

        <li className="page-item active">
          <button className="page-link" onClick={() => handlePageClick(paginaActual)}>{paginaActual + 1}</button>
        </li>

        {paginaActual < paginasTotales - 1 && (
          <li className="page-item">
            <button className="page-link" onClick={() => handlePageClick(paginaActual + 1)}>{paginaActual + 2}</button>
          </li>
        )}

        <li className={`page-item ${paginaActual === paginasTotales - 1 ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageClick(Math.min(paginasTotales - 1, paginaActual + 1))} aria-disabled={paginaActual === paginasTotales - 1}>Siguiente</button>
        </li>
      </ul>
    </nav>
  );
};

export default Paginador;
