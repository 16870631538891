import React, { useState, useEffect } from 'react';
import './header.css';
import logoMenuMobile from '../../assets/Logo_mobile.svg';
import iconClose from '../../assets/icons-close.svg';
import Buzon from '../Buzon/Buzon';
import { useDispatch, useSelector } from 'react-redux';
import { toggleHideImages, toggleEnlargeText, toggleGrayscale } from '../../context/redux/slicers/accessibilitySlice';
import { Tooltip } from 'react-tooltip';

const Header = () => {
    const dispatch = useDispatch();
    const { hideImages, enlargeText, grayscale } = useSelector((state) => state.accessibility);
    const [menuOpen, setMenuOpen] = useState(false);
    const [accessOpen, setAccessOpen] = useState(false);
    const itemsMenu = window.IndexMenu.principal;
    const url = "https://cmsportal.hidalgo.gob.mx";
    const [homes, setHomes] = useState([]);
    const [showTooltip, setShowTooltip] = useState({ buzon: false, accesibilidad: false });
    const isMobile = window.innerWidth > 768;


    useEffect(() => {
        fetch(`${url}/homes`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setHomes(data);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }, []);

    const handleMouseOverAccesibilidad = () => {
        setShowTooltip({ ...showTooltip, accesibilidad: true });
    };

    const handleMouseOutAccesibilidad = () => {
        setShowTooltip({ ...showTooltip, accesibilidad: false });
    };

    const handleMouseOverBuzon = () => {
        setShowTooltip({ ...showTooltip, buzon: true });
    };

    const handleMouseOutBuzon = () => {
        setShowTooltip({ ...showTooltip, buzon: false });
    };

    const toggleMenu = () => setMenuOpen(!menuOpen);
    const accessMenu = () => setAccessOpen(!accessOpen);

    return (
        <header className={`header ${hideImages || homes[0]?.Veda ? 'image-none' : ''} ${grayscale || homes[0]?.Veda ? 'image-gray' : ''} ${enlargeText === 1 ? 'level1' : enlargeText === 2 ? 'level2' : enlargeText === 3 ? 'level3' : ''}`}>
            <div className="logo">
                {/* <img className={`gobernador `} src={`${url}${homes[0]?.header?.imagenGobernador?.url}`} /> */}
                <img className={`estrella `} src={`${url}${homes[0]?.header?.logoGobierno?.url}`} />
                <div className='logo-text'>
                    <p className={`title`}>{homes[0]?.header?.nombreGobernador}</p>
                    {/* <p className={`subtitle`}>{homes[0]?.header?.infoGobernador}</p> */}
                </div>
            </div>
            <div className='logo-gob-hgo'>
                <img src={`${url}${homes[0]?.header?.logoHidalgo?.url}`} />
            </div>
            <nav className={`nav ${menuOpen ? 'open' : ''} `}>
                <div className='button-close-mobile'>
                    <button onClick={toggleMenu}>
                        <img src={iconClose} />
                    </button>
                </div>
                <ul className='menu'>
                    {
                        itemsMenu.map((item, index) => (
                            <li key={item.titulo} className={`menu-item ${item?.subpaginas && item?.subpaginas?.length > 0 ? 'submenu' : ''} `}>
                                <a className={`menu-item-a`} href={item.url}>{item.titulo}</a>
                                {
                                    item?.subpaginas && item?.subpaginas?.length > 0 && (
                                        <ul className={`submenu-items `}>
                                            {
                                                item?.subpaginas.map((subitem, index) => (
                                                    <li><a href={subitem.url}>{subitem.titulo}</a></li>
                                                ))
                                            }
                                        </ul>
                                    )
                                }
                            </li>
                        ))
                    }
                </ul>
            </nav>
            <div className='header-end'>
                <img className={`logo-gob-hidalgo-2 `} src={`${url}${homes[0]?.header?.logoHidalgo?.url}`} />
                <div className='buttons'>
                    <div className={`access-menu ${accessOpen ? 'openAccess' : ''} `}>
                        <div className='box-tootip' onMouseOver={handleMouseOverAccesibilidad} onMouseOut={handleMouseOutAccesibilidad}>
                            <button data-tooltip-id='idAccesibilidad' className='' onClick={accessMenu}>
                                <img src={`${url}${homes[0]?.header?.iconAccesibilidad?.url}`} />
                            </button>
                            <Tooltip
                                id="idAccesibilidad"
                                content={`Accesibilidad`}
                                place='left'
                            />
                            {/* {showTooltip.accesibilidad && isMobile && <div className="tooltip-custom">Accesibilidad</div>} */}
                        </div>
                        <div className={`buttons-access `}>
                            <button onClick={() => dispatch(toggleEnlargeText())}>
                                <img src={`${url}${homes[0]?.header?.iconTexto?.url}`} />
                            </button>
                            <div className='indicador-access'>
                                <div className={`active`}></div>
                                <div className={`${enlargeText >= 2 ? 'active' : ''}`}></div>
                                <div className={`${enlargeText >= 3 ? 'active' : ''}`}></div>
                            </div>
                            <button onClick={() => dispatch(toggleGrayscale())}>
                                <img src={`${url}${homes[0]?.header?.iconTema?.url}`} />
                            </button>
                            <button onClick={() => dispatch(toggleHideImages())}>
                                <img src={`${url}${homes[0]?.header?.iconImagen?.url}`} />
                            </button>
                        </div>
                    </div>
                    <div className='box-tootip buzon' onMouseOver={handleMouseOverBuzon} onMouseOut={handleMouseOutBuzon}>
                        <Buzon id='idBuzon' icon={`${url}${homes[0]?.header?.iconBuzon?.url}`} />
                        <Tooltip
                            id="idBuzon"
                            content={`Buzón`}
                            place='left'
                        />
                        {/* {showTooltip.buzon && isMobile && <div className='tooltip-custom'>Buzón</div>} */}
                    </div>
                    <button className="menu-toggle" onClick={toggleMenu}>
                        <img src={logoMenuMobile} />
                    </button>
                </div>
            </div>
        </header>
    );
}

export default Header;