export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('accessibilityState', serializedState);
    } catch (e) {
        console.warn('Error guardando el estado en localStorage', e);
    }
};

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('accessibilityState');
        if (serializedState === null) {
            return undefined; // Si no hay estado guardado, devuelve undefined para usar el estado inicial
        }
        return JSON.parse(serializedState);
    } catch (e) {
        console.warn('Error cargando el estado desde localStorage', e);
        return undefined;
    }
};  