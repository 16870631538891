import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    hideImages: false,
    enlargeText: 1,
    grayscale: false,
};

const accessibilitySlice = createSlice({
    name: 'accessibility',
    initialState,
    reducers: {
        toggleHideImages: (state) => {
            state.hideImages = !state.hideImages;
        },
        toggleEnlargeText: (state) => {
            state.enlargeText = state.enlargeText === 3 ? 1 : state.enlargeText + 1;
        },
        toggleGrayscale: (state) => {
            state.grayscale = !state.grayscale;
        },
    },
});

export const { toggleHideImages, toggleEnlargeText, toggleGrayscale } = accessibilitySlice.actions;
export default accessibilitySlice.reducer;